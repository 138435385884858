import { useEffect } from 'react';

// custom hook para fazer o scroll para o inicio da pagina
export const useScrollToTop = () => {
  // hook resposável por trabalhar com efeitos colaterais.
  // primeiro parâmetro é uma function e o segundo é um array de dependencias.
  // se o array tiver vazio, ele executa somente quando carrega a página.
  useEffect(() => {
    //codigo javascript que faz scroll para o x:0 e y:0
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);
};
