import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Hero from 'components/molecules/Hero';
import Heading from 'components/atoms/Heading';
import Section from 'components/molecules/Section';
import Grid from 'components/atoms/Grid';
import Footer from 'components/organisms/Footer';
import Card, { CardMedia, CardMediaDescription } from 'components/atoms/Card';
import BreadCrumb from 'components/atoms/BreadCrumb';
import Location from 'components/molecules/Location';

import BgSoyHarvestImage from '../../assets/soy-harvest.jpg';
import AboutImage from 'draws/About';

import Valtra7500 from '../../assets/machines/valtra-bc7500.jpg';
import Case2399 from '../../assets/machines/case-2399.jpg';
import Case2399x2 from '../../assets/machines/case-2399-2.jpg';
import Case2399x3 from '../../assets/machines/case-2399-3.jpg';
import Case2688 from '../../assets/machines/case-2688.jpg';
import Case2688x2 from '../../assets/machines/case-2688-2.jpg';
import Case2388 from '../../assets/machines/case-2388.jpg';
import Massey9790 from '../../assets/machines/mf-9790.jpg';
import NewHollandTC5090 from '../../assets/machines/nh-tc5090.jpg';
import NewHollandTC59 from '../../assets/machines/nh-tc59.jpg';

const ImageContainer = styled.div`
  svg {
    width: 100%;
    height: auto;
    max-width: 380px;
    color: ${props => props.theme.colors.primary.main};
  }
`;

const machines = [
  {
    id: 1,
    name: 'Valtra BC 7500',
    img: Valtra7500,
  },
  {
    id: 2,
    name: 'MF 9790 - 01',
    img: Massey9790,
  },
  {
    id: 3,
    name: 'MF 9790 - 02',
    img: Massey9790,
  },
  {
    id: 4,
    name: 'Case 2399 - 01',
    img: Case2399,
  },
  {
    id: 5,
    name: 'Case 2399 - 02',
    img: Case2399x2,
  },
  {
    id: 6,
    name: 'Case 2399 - 03',
    img: Case2399x3,
  },
  {
    id: 7,
    name: 'Case 2688 - 01',
    img: Case2688,
  },
  {
    id: 8,
    name: 'Case 2688 - 02',
    img: Case2688x2,
  },
  {
    id: 9,
    name: 'Case 2388 - 01',
    img: Case2388,
  },
  {
    id: 10,
    name: 'Case 2388 - 02',
    img: Case2388,
  },
  {
    id: 11,
    name: 'Case 2388 - 03',
    img: Case2388,
  },
  {
    id: 12,
    name: 'NH TC 5090',
    img: NewHollandTC5090,
  },
  {
    id: 13,
    name: 'NH TC 59',
    img: NewHollandTC59,
  },
  {
    id: 14,
    name: 'NH TC 57',
    img: NewHollandTC59,
  },
];

const About = () => (
  <>
    <Hero image={BgSoyHarvestImage}>
      <Heading>
        <h1>TR Colheitas e Transportes</h1>
      </Heading>
      <BreadCrumb
        items={[{ label: 'Início', link: '/' }, { label: 'Sobre' }]}
      />
    </Hero>
    <Section>
      <Grid md={2}>
        <div>
          <p>
            Idealizada pelo empresário Quiliano Rauber, a TR Colheitas e
            Transportes, sediada no município de Salvador das Missões - RS,
            possui mais de 15 anos de experiência no setor de agricultura. Desde
            então, presta serviços de colheitas, plantio e transportes nos
            estados do Rio Grande do Sul, Mato Grosso do Sul, Mato Grosso e
            Goias.
          </p>
          <p>
            Conta com completa frota de colheitadeiras e implementos agrícolas
            necessários para o plantio, manejo e colheita de produtos agrícolas
            como soja, milho e trigo. Também contamos com uma frota de caminhões
            para transporte de implementos e produção agrícola.
          </p>
          <p>Serviços especializados: </p>
          <ul>
            <li>Serviço de colheitas em geral</li>
            <li>Preparação de terreno para plantio.</li>
            <li>Plantio de culturas como soja, trigo e milho</li>
            <li>Serviço de transportes gerais e de implementos agrícolas.</li>
          </ul>
        </div>
        <div>
          <p>
            Ficou interessado? Envie uma mensagem para nossa equipe através da
            página de <Link to={'/contact'}>contato</Link> para saber mais sobre
            nossos serviços.
          </p>
          <center>
            <ImageContainer>
              <AboutImage />
            </ImageContainer>
          </center>
        </div>
      </Grid>
    </Section>
    {/* <Section inverse>
      <Grid md={2}>
        <div>
          <h4>Missão</h4>
          <p>
            Eiusmod nostrud Lorem eu velit sunt elit. Sit nostrud excepteur
            exercitation excepteur ea veniam tempor officia cupidatat. Nisi ut
            ullamco aliqua adipisicing ipsum dolore aliqua ad dolore non ex in
            occaecat irure.
          </p>
        </div>
        <div>
          <h4>Visão</h4>
          <p>
            Adipisicing aute nulla dolor nisi Lorem proident duis nostrud sunt
            aliqua. Esse quis velit sunt anim voluptate laborum pariatur. Non ut
            eiusmod sunt sint esse ipsum incididunt proident enim aliqua anim
            magna sint.
          </p>
        </div>
      </Grid>
    </Section> */}
    <Section inverse>
      <Heading>
        <h2>Conheça nossa frota</h2>
      </Heading>
      <Grid sm={1} md={2} lg={3}>
        {machines.map(machine => (
          <Card key={machine.id}>
            <CardMedia image={machine.img}>
              <CardMediaDescription>
                <h5>{machine.name}</h5>
              </CardMediaDescription>
            </CardMedia>
          </Card>
        ))}
      </Grid>
    </Section>
    <Section>
      <Heading>
        <h2>Localização</h2>
      </Heading>
      <Location />
    </Section>
    <Footer />
  </>
);

export default About;
