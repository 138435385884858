import React from 'react';

import ContactPage from '../components/pages/Contact';

import { useScrollToTop } from 'hooks/scroll';

const Contact = () => {
  useScrollToTop();

  return <ContactPage />;
};

export default Contact;
