import React from 'react';
import styled from 'styled-components';
import { FaIdCard, FaHome, FaScroll } from 'react-icons/fa';

import Hero from 'components/molecules/Hero';
import Heading from 'components/atoms/Heading';
import Section from 'components/molecules/Section';
import Footer from 'components/organisms/Footer';
import Callout, { CalloutBody, CalloutActions } from 'components/atoms/Callout';
import Button from 'components/atoms/Button';
import BreadCrumb from 'components/atoms/BreadCrumb';

import BgSoyHarvestImage from '../../assets/soy-harvest.jpg';

import ProductType from '../../model/types/ProductType';

const PinnedList = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const PinnedItem = styled.li`
  display: inline-block;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 4px;
  padding: 8px 16px;
  margin: 0 16px 16px 0;

  svg {
    margin-right: 8px;
    vertical-align: middle;
  }
`;

const ProductDetail = ({ product }) => (
  <>
    <Hero image={BgSoyHarvestImage}>
      <Heading>
        <h1>{product.title}</h1>
      </Heading>
      <BreadCrumb
        items={[
          { label: 'Início', link: '/' },
          { label: 'Serviços' },
          { label: product.title },
        ]}
      />
    </Hero>
    <Section>
      <p>
        Aliquip in amet eiusmod incididunt laborum amet reprehenderit officia
        sint exercitation sit ipsum ea consectetur. Qui laboris irure ipsum
        commodo est culpa. Cupidatat do in pariatur aliqua commodo qui et aute.
      </p>
      <p>
        Nulla nisi qui dolor labore exercitation excepteur occaecat ullamco.
        Mollit anim ex aliquip aute reprehenderit magna sunt adipisicing
        cupidatat et est non esse mollit. Aliquip laborum fugiat enim aute sit.
        Nostrud aliqua aute velit sunt anim aute amet nulla ut duis tempor anim.
        Esse deserunt non officia irure adipisicing reprehenderit. Incididunt
        sint minim aliqua aute sit nisi deserunt ullamco officia. Ad sint
        deserunt duis exercitation officia voluptate deserunt id ex commodo.
      </p>
      <p>
        Do nisi adipisicing adipisicing amet anim. Nisi excepteur pariatur dolor
        culpa eu anim veniam pariatur dolor aliquip. Cupidatat nisi cillum non
        sunt deserunt id qui ullamco aute duis.
      </p>
      <h5>Documentos para contratação</h5>
      <PinnedList>
        <PinnedItem>
          <FaIdCard />
          RG
        </PinnedItem>
        <PinnedItem>
          <FaScroll />
          CPF
        </PinnedItem>
        <PinnedItem>
          <FaHome />
          Comprovante de Endereço
        </PinnedItem>
      </PinnedList>
    </Section>
    <Section inverse>
      <Callout>
        <CalloutBody>
          <h6>Title</h6>
          <p>
            Adipisicing labore laborum quis voluptate deserunt ullamco cupidatat
            ad minim excepteur. In mollit sunt pariatur magna enim labore culpa
            officia sint eu commodo excepteur dolor. Occaecat veniam et
            voluptate reprehenderit excepteur in voluptate qui. Sint commodo non
            ad id nisi culpa anim amet.
          </p>
          <CalloutActions>
            <Button color="primary">Entrar em contato</Button>
          </CalloutActions>
        </CalloutBody>
      </Callout>
    </Section>
    <Footer />
  </>
);

ProductDetail.defaultProps = {
  product: {},
};

ProductDetail.propTypes = {
  product: ProductType,
};

export default ProductDetail;
