import React, { useState } from 'react';
import styled from 'styled-components';
import { Navigate } from 'react-router-dom';

import Hero from 'components/molecules/Hero';
import Heading from 'components/atoms/Heading';
import Section from 'components/molecules/Section';
import Grid from 'components/atoms/Grid';
import Footer from 'components/organisms/Footer';
import BreadCrumb from 'components/atoms/BreadCrumb';
import Button from 'components/atoms/Button';
import Input, { TextArea } from 'components/atoms/Input';
import Location from 'components/molecules/Location';

import postMessage from '../../services/postMessage';
import useContactMessageForm from '../../hooks/contactMessage';

import BgSoyHarvestImage from '../../assets/soy-harvest.jpg';

const FormContainer = styled.div`
  display: block;
  width: 90%;
`;

const ButtonContainer = styled.div`
  margin-top: 16px;
`;

const Contact = () => {
  const send = () => {
    try {
      postMessage({
        name: inputs.name,
        email: inputs.email,
        phone: inputs.phone,
        message: inputs.message,
      });
      alert('Obrigado por entrar em contato.\nRetornaremos em breve.');
      setRedirect(true);
    } catch (err) {
      console.log(err);
    }
  };

  const [redirect, setRedirect] = useState(false);
  const { inputs, handleInputChange, handleSubmit } =
    useContactMessageForm(send);

  return (
    <>
      {redirect && <Navigate to="/" />}
      <Hero image={BgSoyHarvestImage}>
        <Heading>
          <h1>TR Colheitas e Transportes</h1>
        </Heading>
        <BreadCrumb
          items={[{ label: 'Início', link: '/' }, { label: 'Contato' }]}
        />
      </Hero>
      <Section>
        <Grid md={2}>
          <div>
            <Heading>
              <h2>Contato</h2>
            </Heading>
            <FormContainer>
              <form onSubmit={handleSubmit}>
                <Input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Nome"
                  onChange={handleInputChange}
                  autofocus
                  required
                />
                <Input
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="Telefone"
                  onChange={handleInputChange}
                  required
                />
                <Input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleInputChange}
                />
                <TextArea
                  id="message"
                  name="message"
                  placeholder="Mensagem"
                  onChange={handleInputChange}
                ></TextArea>

                <ButtonContainer>
                  <Button type="submit" color="primary">
                    Enviar
                  </Button>
                </ButtonContainer>
              </form>
            </FormContainer>
          </div>
          <div>
            <Heading>
              <h2>Localização</h2>
            </Heading>
            <Location />
          </div>
        </Grid>
      </Section>
      <Footer />
    </>
  );
};

export default Contact;
