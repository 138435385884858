import React from 'react';
import { useParams } from 'react-router-dom';

import ProductDetailPage from '../../components/pages/ProductDetail';
import Error404 from '../../components/pages/Error';

import NotFound from '../../draws/NotFound';

import { useScrollToTop } from '../../hooks/scroll';
import { useProduct } from '../../hooks/products';

const ProductDetail = () => {
  useScrollToTop();

  const { slang } = useParams();
  const product = useProduct({ slang });

  if (!product) {
    return (
      <Error404
        title="Serviço não encontrado"
        image={<NotFound />}
        description="Ops! Serviço não encontrado ou não disponível."
      ></Error404>
    );
  }
  return <ProductDetailPage product={product} />;
};

export default ProductDetail;
