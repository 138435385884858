import React from 'react';

import Error from '../components/pages/Error';
import NotFound from '../draws/NotFound';

const Error404 = () => (
  <Error
    title="Página não encontrada"
    image={<NotFound />}
    description="Ops! Essa página não existe."
  ></Error>
);

export default Error404;
