import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Label = styled.label`
  margin: 25px 0 6px 0;
  display: block;
`;

const ImputContainer = styled.input`
  background: #f7f7f7;
  border-radius: 5px;
  border: 3px solid transparent;
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 1.3em;

  &:focus {
    border-color: ${props => props.theme.colors.primary.main};
    outline: none;
  }
`;

const TextAreaContainer = styled.textarea.attrs({
  maxLength: 150,
  rows: 4,
})`
  background: #f7f7f7;
  border-radius: 5px;
  border: 3px solid transparent;
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 1.3em;

  &:focus {
    border-color: ${props => props.theme.colors.primary.main};
    outline: none;
  }
`;

export const TextArea = ({ id, name, placeholder, onChange }) => (
  <>
    <Label htmlFor={id}>{placeholder}</Label>
    <TextAreaContainer
      id={id}
      name={name}
      onChange={onChange}
    ></TextAreaContainer>
  </>
);

const Input = ({
  type,
  name,
  id,
  placeholder,
  autofocus,
  required,
  onChange,
}) => (
  <>
    <Label htmlFor={id}>{placeholder}</Label>
    <ImputContainer
      type={type}
      name={name}
      id={id}
      required={required}
      autoFocus={autofocus}
      onChange={onChange}
    />
  </>
);

Input.defaultProps = {
  type: 'text',
  name: '',
  id: '',
  placeholder: '',
  onChange: () => {},
  autofocus: undefined,
  required: undefined,
};

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  autofocus: PropTypes.bool,
  required: PropTypes.bool,
};

export default Input;
