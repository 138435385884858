import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

import Section from '../molecules/Section';
import Grid from '../atoms/Grid';
import Heading from '../atoms/Heading';
import Button from '../atoms/Button';

const ImageContainer = styled.div`
  svg {
    width: 100%;
    height: auto;
    max-width: 300px;
    color: ${props => props.theme.colors.primary.main};
  }
`;

const ErrorTitle = styled.h1`
  margin-top: 0;
`;

const ErrorMessage = styled.p`
  margin: 24px 0;
  font-size: 1.2rem;
`;

const Error = ({ image, title, description }) => (
  <Section>
    <Grid sm={2}>
      <div>
        <Heading>
          <ErrorTitle>{title}</ErrorTitle>
        </Heading>
        <ErrorMessage>{description}</ErrorMessage>
        <Button as={Link} to="/" color="primary">
          Ir para página inicial
        </Button>
      </div>
      <div>
        <ImageContainer>{image}</ImageContainer>
      </div>
    </Grid>
  </Section>
);

Error.defaultProps = {
  image: undefined,
  title: '',
  description: '',
};

Error.propTypes = {
  image: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Error;
