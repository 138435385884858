import CornHarvest from '../assets/corn-harvest.jpg';
import ValtraMachine from '../assets/valtra.jpg';
import Harvester from '../assets/harvester.jpg';

const products = [
  {
    id: 1,
    title: 'Colheita de milho',
    slang: 'colheita-milho',
    image: CornHarvest,
    summary:
      'Contamos com plataformas do tipo espigadeiras de 12 e 14 linhas, especialmente desenvolvidas para colheita de milho.',
  },
  {
    id: 2,
    title: 'Colheita de soja',
    slang: 'colheita-soja',
    image: ValtraMachine,
    summary:
      'Também contamos com plataformas do tipo draper e caracol, com tamanhos variando entre 25, 30, 35 e 40 pés. Esses modelos de plataformas são desenvolvidos para o uso na colheita de diversas culturas, tais como soja, trigo e canola.',
  },
  {
    id: 3,
    title: 'Colheita de trigo',
    slang: 'colheita-trigo',
    image: Harvester,
    summary:
      'Também contamos com plataformas do tipo draper e caracol, com tamanhos variando entre 25, 30, 35 e 40 pés. Esses modelos de plataformas são desenvolvidos para o uso na colheita de diversas culturas, tais como soja, trigo e canola.',
  },
  {
    id: 4,
    title: 'Transportes',
    slang: 'transportes',
    image: CornHarvest,
    summary:
      'Prestamos serviço de transporte de produção agrícola através de carreta e caminão graneleiro. Oferecemos serviço de transportes de maquinas pesadas através de carreta prancha. E também prestamos serviço com caminhão munck, para mais diversas cargas.',
  },
  {
    id: 5,
    title: 'Plantio',
    slang: 'plantio',
    image: ValtraMachine,
    summary:
      'Oferecemos serviço de plantio das mais variadas culturas, como soja, milho, trigo e canola. Temos todos os implementos necessários para a execução do plantio da sua lavoura.',
  },
];

export const useProducts = () => {
  return products;
};

export const useProduct = ({ slang }) => {
  return products.find(p => p.slang === slang);
};
