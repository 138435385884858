import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const ButtonColors = {
  default: 'default',
  primary: 'primary',
  danger: 'danger',
};

export const ButtonVariants = {
  default: 'default',
  outlined: 'outlined',
  linked: 'linked',
};

const getDarkColor = ({ theme, color }) => {
  switch (color) {
    case ButtonColors.primary:
      return theme.colors.primary.dark;
    case ButtonColors.danger:
      return theme.colors.danger.dark;
    default:
      return '#5a6268';
  }
};

const getMainColor = ({ theme, color }) => {
  switch (color) {
    case ButtonColors.primary:
      return theme.colors.primary.main;
    case ButtonColors.danger:
      return theme.colors.danger.main;
    default:
      return '#e0e0e0';
  }
};

const getColorText = ({ theme, color }) => {
  switch (color) {
    case ButtonColors.primary:
      return theme.colors.primary.text;
    case ButtonColors.danger:
      return theme.colors.danger.text;
    default:
      return '#212121';
  }
};

const getOutlinedText = props => {
  if (props.color === ButtonColors.default) {
    return '#212121';
  }
  return getMainColor(props);
};

const getLinkedText = props => {
  if (props.color === ButtonColors.default) {
    return '#757575';
  }
  return getMainColor(props);
};

const Button = styled.button`
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 12px 36px;
  cursor: pointer;
  background-color: ${getMainColor};
  border: 2px solid ${getMainColor};
  color: ${getColorText};
  display: inline-block;
  text-decoration: none;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:hover:not(disabled) {
    background-color: ${getDarkColor};
    border-color: ${getDarkColor};
  }
`;

const ButtonOutlined = styled(Button)`
  background-color: transparent;
  color: ${getOutlinedText};

  &:hover:not(disabled) {
    background-color: transparent;
    color: ${getDarkColor};
  }
`;

const ButtonLinked = styled(Button)`
  background-color: transparent;
  border-color: transparent;
  color: ${getLinkedText};
  padding-left: 0;
  padding-right: 0;

  &:hover:not(disabled) {
    background-color: transparent;
    border-color: transparent;
    color: ${getDarkColor};
  }
`;

// just because storybook dont support styled components props
const ButtonWrapper = props => {
  switch (props.variant) {
    case ButtonVariants.outlined:
      return <ButtonOutlined {...props} />;
    case ButtonVariants.linked:
      return <ButtonLinked {...props} />;
    default:
      return <Button {...props} />;
  }
};

ButtonWrapper.defaultProps = {
  type: 'button',
  children: undefined,
  color: 'default',
};

ButtonWrapper.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.oneOf(Object.values(ButtonColors)),
  variant: PropTypes.oneOf(Object.values(ButtonVariants)),
};

export default ButtonWrapper;
