import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from 'routes/home';
import About from 'routes/about';
import Contact from 'routes/contact';
import ProductDetail from 'routes/services';
import Error404 from 'routes/error404';

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />}></Route>
    <Route path="/about" element={<About />}></Route>
    <Route path="/contact" element={<Contact />}></Route>
    <Route path="/services/:slang" element={<ProductDetail />}></Route>
    <Route path="*" element={<Error404 />}></Route>
  </Routes>
);

export default AppRoutes;
