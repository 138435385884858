import React from 'react';

const title = 'Avenida Independência, S/N, Salvador das Missões, RS, 97940-000';

const Location = () => (
  <address>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14074.828505991129!2d-54.84405395286465!3d-28.124954033241927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjjCsDA3JzAzLjAiUyA1NMKwNTAnMTEuMSJX!5e0!3m2!1spt-BR!2sbr!4v1610858123466!5m2!1spt-BR!2sbr"
      width="100%"
      height="450"
      title={title}
      alt={title}
      frameBorder="0"
      allowFullScreen=""
      aria-hidden="false"
      tabIndex="0"
    ></iframe>
    {title}
  </address>
);

export default Location;
