import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ProductType from '../../model/types/ProductType';

import Hero from '../molecules/Hero';
import BgSoyHarvestImage from '../../assets/soy-harvest.jpg';
import AboutVideo from '../../assets/corn-harvest.mp4';
import Heading from '../atoms/Heading';
import Button from '../atoms/Button';
import Grid from '../atoms/Grid';
import Feature from '../atoms/Feature';
import Section from '../molecules/Section';
import Footer from '../organisms/Footer';
import ProductGrid from '../organisms/ProductGrid';
import Accordion, { AccordionGroup } from '../atoms/Accordion';
import { FaCar, FaKey, FaProjectDiagram, FaMapMarkerAlt } from 'react-icons/fa';

const Home = ({ products }) => (
  <>
    <Hero image={BgSoyHarvestImage}>
      <Heading>
        <h1>
          <strong>TR</strong> Colheitas
          <br />e Transportes
        </h1>
      </Heading>
      <ul>
        <li>Serviço de preparação de terreno, colheita e plantio.</li>
        <li>Especializada em culturas como soja, trigo e milho.</li>
        <li>Serviço de transportes gerais e de implementos agrícolas.</li>
      </ul>
      <Button as={Link} to={'/contact'} color="primary" variant="outlined">
        Entre em contato
      </Button>
    </Hero>
    <main>
      <Section>
        <Grid sm={2} md={4}>
          <Feature icon={<FaKey />} title="Frota de colheitadeiras">
            <p>
              Contamos com completa frota de colheitadeiras. Nos ajustamos de
              acordo com sua necessidade de colheita.
            </p>
          </Feature>
          <Feature icon={<FaCar />} title="Transporte de implementos">
            <p>
              Transportamos com agilidade e segurança seus implementos
              agrícolas, escavadeiras, caminhões entre outros.
            </p>
          </Feature>
          <Feature icon={<FaMapMarkerAlt />} title="Produção agrícola">
            <p>
              Contamos com todos os implementos necessários para o plantio,
              manejo e colheita de produtos agrícolas como soja, milho e trigo.
            </p>
          </Feature>
          <Feature icon={<FaProjectDiagram />} title="Confiança e experiência">
            <p>
              Possuímos mais de 15 anos de experiência na prestação de serviços
              de transportes, plantio e colheitas de lavouras próprias e de
              terceiros.
            </p>
          </Feature>
        </Grid>
      </Section>
      <Section inverse>
        <Heading>
          <h2>Conheça nossos serviços</h2>
        </Heading>
        <ProductGrid products={products} />
      </Section>
      <Section>
        <Grid md={2}>
          <div>
            <Heading>
              <h2>TR Colheitas e Tranportes</h2>
            </Heading>
            <p>
              Idealizada pelo empresário Quiliano Rauber, a TR Colheitas e
              Transportes, sediada no município de Salvador das Missões - RS,
              possui mais de 15 anos de experiência no setor de agricultura.
              Desde então, presta serviços de colheitas, plantio e transportes
              nos estados do Rio Grande do Sul, Mato Grosso do Sul, Mato Grosso
              e Goias.
            </p>
            <div>
              {/* Button with React Router Link - Doesnt refresh the page */}
              <Button as={Link} to={'/about'} color="primary">
                Saiba Mais
              </Button>
            </div>
          </div>
          <div>
            <video
              src={AboutVideo}
              width="100%"
              autoPlay
              playsInline
              loop
              muted
            ></video>
          </div>
        </Grid>
      </Section>
      <Section inverse>
        <Heading>
          <h2>Dúvidas frequentes</h2>
        </Heading>
        <AccordionGroup>
          <Accordion title="Quais modelos e tamanhos de plataformas?">
            Para atender todos os tipos de áreas de produção, contamos com
            plataformas de 30, 35 e 40 pés nos modelos caracol e dreeper. Também
            possuímos espigadeiras de 12 e 14 linhas para colheita de milho.
          </Accordion>
          <Accordion title="Quantas colheitadeiras são necessárias para minha lavoura?">
            Varia de acordo com o tamanho da lavoura, fase de maturação da
            cultura, condições climáticas etc. Nos adaptamos a sua necessidade.
            Entre em <Link to={'/contact'}>contato</Link> para saber mais.
          </Accordion>
          <Accordion title="Como faço para efetivar a contratação?">
            A efetivação do serviço de colheita pode ser feito através do
            whatsapp, telefone ou pelo formulário do site. Entre em contato,
            nossa equipe estará pronta para lhe atender.
          </Accordion>
        </AccordionGroup>
      </Section>
    </main>
    <Footer />
  </>
);

Home.defaultProps = {
  products: [],
};

Home.propTypes = {
  products: PropTypes.arrayOf(ProductType),
};

export default Home;
